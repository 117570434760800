var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c("div", { staticClass: "wrap" }, [
        _c(
          "div",
          { staticClass: "left" },
          [
            _c(
              "el-button-group",
              _vm._l(_vm.nav, function (item, index) {
                return _c(
                  "el-button",
                  {
                    key: index,
                    attrs: {
                      type: _vm.form.type == index + 1 ? "primary" : "",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.navClick(index)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(item) + " ")]
                )
              }),
              1
            ),
          ],
          1
        ),
        _vm.shwoCon
          ? _c(
              "div",
              { staticClass: "right" },
              [
                _c("i", {
                  staticClass: "el-icon-warning",
                  staticStyle: {
                    "margin-right": "15px",
                    "font-size": "22px",
                    color: "#f60",
                    "vertical-align": "middle",
                    cursor: "pointer",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$message(
                        "请先选择一个一级科目，再点击添加科目"
                      )
                    },
                  },
                }),
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.handleAdd } },
                  [_vm._v("添加科目")]
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "table",
          staticStyle: { "margin-top": "15px" },
          attrs: {
            data: _vm.list,
            "default-expand-all": "",
            "row-key": "id",
            "max-height": "600",
            indent: 34,
            "row-class-name": _vm.rowClass,
            "highlight-current-row": "",
            "tree-props": { children: "children", hasChildren: "hasChildren" },
          },
          on: { "current-change": _vm.handleCurrentChange },
        },
        [
          _c(
            "el-table-column",
            { attrs: { align: "center", type: "index", width: "50" } },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        "popper-class": "custom-table-checkbox",
                        trigger: "hover",
                      },
                    },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          model: {
                            value: _vm.checkList,
                            callback: function ($$v) {
                              _vm.checkList = $$v
                            },
                            expression: "checkList",
                          },
                        },
                        _vm._l(_vm.columns, function (item, index) {
                          return _c("el-checkbox", {
                            key: index,
                            attrs: { label: item.label },
                          })
                        }),
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { slot: "reference", type: "text" },
                          slot: "reference",
                        },
                        [
                          _c("vab-remix-icon", {
                            attrs: { icon: "settings-line" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _vm._l(_vm.finallyColumns, function (item, tableIndex) {
            return _c("el-table-column", {
              key: tableIndex,
              attrs: {
                prop: item.prop,
                label: item.label,
                width: "",
                type: item.type,
                align: "center",
              },
            })
          }),
          _vm.shwoCon
            ? _c("el-table-column", {
                attrs: {
                  prop: "",
                  align: "center",
                  label: "操作",
                  width: "100",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var $index = ref.$index
                        var row = ref.row
                        return [
                          row.dealer_id != 0
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      nativeOn: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.handleEditRow($index, row)
                                        },
                                      },
                                    },
                                    [_vm._v(" 编辑 ")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      nativeOn: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.handleDeleteRow(
                                            $index,
                                            row
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" 删除 ")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2818206381
                ),
              })
            : _vm._e(),
        ],
        2
      ),
      _c("edit", { ref: "edit", on: { refresh: _vm.fetchData } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }