import request from '@/utils/request'

// 会计科目列表
export function getList(data) {
  return request({
    url: '/cashAdmin/subject/list',
    method: 'post',
    data,
  })
}
// 期初建账接口
export function getList2(data) {
  return request({
    url: '/cashAdmin/subject-detail/begin-list',
    method: 'post',
    data,
  })
}
// 删除科目
export function deleteRow(data) {
  return request({
    url: '/cashAdmin/subject/delete',
    method: 'post',
    data,
  })
}

// 新增科目
export function addRow(data) {
  return request({
    url: '/cashAdmin/subject/add',
    method: 'post',
    data,
  })
}

// 新增时获取编码
export function newCode(data) {
  return request({
    url: '/cashAdmin/subject/code',
    method: 'post',
    data,
  })
}

// 编辑科目
export function editRow(data) {
  return request({
    url: '/cashAdmin/subject/update',
    method: 'post',
    data,
  })
}
