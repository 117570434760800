<!--
 * @Author: your name  LH
 * @Date: 2021-01-26 14:43:22
 * @LastEditTime: 2021-01-26 14:43:58
 * @LastEditors: Please set LastEditors
 * @Description: 会计科目设置
 * @FilePath: \sd-vue-admin\src\views\setting\otherManagement\accounting\index.vue
-->
<template>
  <!-- NAME[epic=设置] 会计科目设置 -->

  <div class="orderTest-container">
    <div class="wrap">
      <div class="left">
        <el-button-group>
          <el-button
            v-for="(item, index) in nav"
            :key="index"
            :type="form.type == index + 1 ? 'primary' : ''"
            @click="navClick(index)"
          >
            {{ item }}
          </el-button>
        </el-button-group>
      </div>
      <div v-if="shwoCon" class="right">
        <i
          class="el-icon-warning"
          style="
            margin-right: 15px;
            font-size: 22px;
            color: #f60;
            vertical-align: middle;
            cursor: pointer;
          "
          @click="$message('请先选择一个一级科目，再点击添加科目')"
        ></i>
        <el-button type="primary" @click="handleAdd">添加科目</el-button>
      </div>
    </div>
    <el-table
      ref="table"
      v-loading="loading"
      :data="list"
      style="margin-top: 15px"
      default-expand-all
      row-key="id"
      max-height="600"
      :indent="34"
      :row-class-name="rowClass"
      highlight-current-row
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      @current-change="handleCurrentChange"
    >
      <!-- 序号 -->
      <el-table-column align="center" type="index" width="50">
        <template slot="header">
          <el-popover popper-class="custom-table-checkbox" trigger="hover">
            <el-checkbox-group v-model="checkList">
              <el-checkbox
                v-for="(item, index) in columns"
                :key="index"
                :label="item.label"
              ></el-checkbox>
            </el-checkbox-group>
            <el-button slot="reference" type="text">
              <vab-remix-icon icon="settings-line" />
            </el-button>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(item, tableIndex) in finallyColumns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        width=""
        :type="item.type"
        align="center"
      ></el-table-column>
      <el-table-column
        v-if="shwoCon"
        prop=""
        align="center"
        label="操作"
        width="100"
      >
        <template #default="{ $index, row }">
          <div v-if="row.dealer_id != 0">
            <el-button
              type="text"
              @click.native.prevent="handleEditRow($index, row)"
            >
              编辑
            </el-button>
            <el-button
              type="text"
              @click.native.prevent="handleDeleteRow($index, row)"
            >
              删除
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <edit ref="edit" @refresh="fetchData"></edit>
  </div>
</template>
<script>
  import _ from 'lodash'
  import { getList, deleteRow } from '@/api/setting'
  import Edit from './components/edit'
  export default {
    name: 'Accounting',
    components: { Edit },
    props: {
      shwoCon: {
        type: Boolean,
        default: () => true,
      },
    },
    data() {
      return {
        loading: false,
        selectRow: '',
        form: {
          pageNo: 1, //
          pageSize: -1, //-1表示不分页
          type: 1, //科目类别，默认是1，1资产类2负债类3权益类4损益类(收入)5损益类(支出)
          depart_id: '', //部门id
        },
        // 科目类别，默认是1，1资产类2负债类3权益类4损益类(收入)5损益类(支出)
        nav: ['资产类', '负债类', '权益类', '损益类(收入)', '损益类(支出)'],
        list: [],
        checkList: ['科目代码', '科目名称', '部门', '状态'],
        columns: [
          {
            order: 1,
            label: '科目代码',
            prop: 'bill_code',
            width: '',
            type: '',
          },
          {
            order: 2,
            label: '科目名称',
            prop: 'name',
            width: '',
          },
          {
            order: 3,
            label: '部门',
            prop: 'depart_name',
            width: '',
          },
          {
            order: 4,
            label: '状态',
            prop: 'status_text',
            width: '100',
          },
        ],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {},
    created() {
      this.fetchData()
    },
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true
        let { data, code, msg, totalCount } = await getList(this.form)
        console.log(data)
        this.list = data
        this.total = totalCount
        this.loading = false
      },
      navClick(index) {
        if (this.form.type != index + 1) {
          this.form.type = index + 1
          this.fetchData()
        }
        // /科目类别，默认是1，1资产类2负债类3权益类4损益类(收入)5损益类(支出)
      },

      rowClass({ row, rowIndex }) {
        if (row.level == '1') {
          return 'blue'
        } else {
          return ''
        }
      },
      handleCurrentChange(val) {
        this.selectRow = val
        this.$emit('current-row', val)
      },
      handleSelectRow(a, val) {
        console.log(val)

        this.selectRow = val
      },
      handleAdd() {
        // 添加
        // this.$refs.edit.aname = ''
        if (this.selectRow) {
          console.log(this.selectRow)
          if (this.selectRow.level != 1) {
            this.$message.error('请选择一级科目新建子科目')
          } else if (this.selectRow.is_child == 1) {
            this.$message.error('该科目不允许被添加二级科目')
          } else {
            // 添加科目
            // alert(1)
            this.$refs.edit.title = '新增科目/账户'
            this.$refs.edit.aname = this.selectRow.name
            this.$refs.edit.form.parent_id = this.selectRow.id

            this.$refs.edit.showDialog = true
          }
        } else {
          this.$message.error('请选择一项数据')
        }
      },
      handleEditRow(index, row) {
        console.log(row)
        this.$refs.edit.title = '编辑科目/账户'
        this.$refs.edit.isAdd = false
        this.$refs.edit.aname = row.parent_text
        this.$refs.edit.form.parent_id = row.id
        this.$refs.edit.form.name = row.name
        this.$refs.edit.form.supp_id = row.supp_id
        this.$refs.edit.form.depart_id = row.depart_ids
        this.$refs.edit.form.bill_code = row.bill_code

        this.$refs.edit.showDialog = true
      },
      handleDeleteRow(index, row) {
        // deleteRow
        this.$confirm('确定删除此项吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            deleteRow({
              id: row.id,
            }).then((res) => {
              if (res.code == 200) {
                this.$message.success('删除成功')
                this.fetchData()
              } else {
                this.$message.error(res.msg)
              }
            })
          })
          .catch(() => {
            console.log('no')
          })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .orderTest-container {
    padding: 20px;
    background: #fff;
    .wrap {
      display: flex;
      justify-content: space-between;
      .left {
        width: 70%;
      }
      .right {
        width: 30%;
        text-align: right;
      }
    }
  }
</style>
<style>
  .blue {
    background: #f8f8ff !important;
  }
</style>
