var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: {
        modal: false,
        title: _vm.title,
        visible: _vm.showDialog,
        width: "600px",
        top: "15vh",
        "close-on-click-modal": false,
        "destroy-on-close": "",
        center: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "orderTest-container",
          staticStyle: { width: "300px", margin: "0 auto" },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                inline: "",
                "label-position": "right",
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "一级科目:", prop: "" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.aname,
                      callback: function ($$v) {
                        _vm.aname = $$v
                      },
                      expression: "aname",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "科目代码:", prop: "bill_code" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.bill_code,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "bill_code", $$v)
                      },
                      expression: "form.bill_code",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "科目名称:", prop: "name" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _vm.aname == "预付账款"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "供应商:", prop: "supp_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: {
                            filterable: "",
                            placeholder: "请选择供应商",
                          },
                          model: {
                            value: _vm.form.supp_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "supp_id", $$v)
                            },
                            expression: "form.supp_id",
                          },
                        },
                        _vm._l(_vm.supplier, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.supp_name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "部门:", prop: "depart_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "200px" },
                      attrs: {
                        clearable: "",
                        multiple: "",
                        placeholder: "全部",
                      },
                      model: {
                        value: _vm.form.depart_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "depart_id", $$v)
                        },
                        expression: "form.depart_id",
                      },
                    },
                    _vm._l(_vm.groupSelect, function (i, idx) {
                      return _c("el-option", {
                        key: idx,
                        attrs: { value: i.id, label: i.text },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态:", prop: "status" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 1 },
                      model: {
                        value: _vm.form.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status",
                      },
                    },
                    [_vm._v("正常")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 2 },
                      model: {
                        value: _vm.form.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status",
                      },
                    },
                    [_vm._v("停用")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "margin-top": "5px", "text-align": "right" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _vm.isAdd
            ? _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
                [_vm._v(" 确定 ")]
              )
            : _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleEdit } },
                [_vm._v("确定")]
              ),
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("关闭")]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }